<template>
    <base-card class="pa-4">
        <v-card-title class="pa-1 pl-3 font-weight-bold">Advance Filter</v-card-title>
        <v-row class="ma-0 pa-0">
            <v-col cols="3">
                <v-select hide-details="auto" v-model="searchModel.status"
                    :items="statusLists" :menu-props="{ bottom: true, offsetY: true }"
                    label="Status"
                    outlined
                ></v-select>
            </v-col>
            <v-col cols="3">
                <v-menu v-model="menu" transition="scale-transition"
                    :close-on-content-click="false" offset-y >
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details="auto"
                                        :value="donateDateText"
                                        label="Donation Range"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <span>{{donateDateTooltip}}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <div class="range-calendar">
                        <vc-date-picker mode="date" class="date-picker-range" v-model="donateDate" is-range :masks="{ L: 'YYYY-MM-DD' }"
                            timezone="Australia/Melbourne" color="indigo" :model-config="{type: 'string', mask: 'YYYY-MM-DD' }"/>                           
                        <v-btn class="ma-2"  dark color="primary" @click="menu=false;">
                            Ok
                        </v-btn>
                    </div>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <v-select hide-details="auto" v-model="searchModel.rec"
                    :items="reconcileLists" :menu-props="{ bottom: true, offsetY: true }"
                    label="Reconcile"
                    outlined
                ></v-select>
            </v-col>
        </v-row>
        <v-card-actions style="cursor:pointer">
            <v-btn type="button" class="ma-2" dark color="info" @click="resetModel()">
                <v-icon left>mdi-refresh</v-icon>
                Reset
            </v-btn>
            <v-btn type="button" class="ma-2" dark color="primary" @click="searchFilter">
                <v-icon small left>mdi-account-search-outline</v-icon>
                Search
            </v-btn>
        </v-card-actions>
    </base-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['clearAdvFilter'],
    emits: ['reset', 'search'],
    data() {
        return {
            donateDate: {
                start: null,
                end: null,
            },
            statusLists: [{text: 'In_Transit', value: 'In_Transit'}, {text: 'Paid', value: 'Paid'}],
            reconcileLists: [{text: 'Yes', value: 1}, {text: 'No', value: 0}],
            searchModel: {},
            menu: false,
        }
    },
    computed: {
        donateDateText () {
            return `${this.$helpers.getMomentDatas('YYYY-MM-DD', this.donateDate.start)} - ${this.$helpers.getMomentDatas('YYYY-MM-DD', this.donateDate.end)}`;
        },
        donateDateTooltip () {
            return `${this.$helpers.getMomentDatas('YYYY-MM-DD', this.donateDate.start)}  to  ${this.$helpers.getMomentDatas('YYYY-MM-DD', this.donateDate.end)}`;
        },
    },
    watch: {
        'clearAdvFilter': function(newVal) {
            if (newVal) {
                this.resetModel('clear')
            }
        }
    },
    mounted() {
        this.donateDate = {
            start:  this.$helpers.getJsDateBeginEnd('start'),
            end: this.$helpers.getJsDateBeginEnd(),
        }
    },
    methods: {

        resetModel(clearAdv) {
            this.searchModel = {};
            this.donateDate ={
                start:  this.$helpers.getJsDateBeginEnd('start'),
                end: this.$helpers.getJsDateBeginEnd(),
            }
            if (!clearAdv) {
                this.$emit('reset');
            }
        },
        searchFilter() {
          console.log(this.donateDate)
            this.searchModel.sd = this.$helpers.getMomentDatas('YYYY-MM-DD', this.donateDate.start);
            this.searchModel.ed = this.$helpers.getMomentDatas('YYYY-MM-DD', this.donateDate.end);
            this.$emit('search', this.searchModel);
        }
    }
}
</script>